import Vue from "vue";
import { createAuth0Client } from "@auth0/auth0-spa-js";

/** Define a default action to perform after authentication */
let instance;
let isInitializing = false;

/** Returns the current instance of the SDK */
const auth0Config = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: process.env.VUE_APP_AUTH0_CALLBACK_URL, // Update with your callback URL
  audience: "",
};
export const initializeAuth0 = async () => {
  if (instance) return instance;

  // If initialization is already in progress, wait for it to complete
  if (isInitializing) {
    return new Promise(resolve => {
      const checkInitialization = () => {
        if (instance) {
          resolve(instance);
        } else {
          setTimeout(checkInitialization, 100);
        }
      };
      checkInitialization();
    });
  }

  // Initialize Auth0 client and Vue instance
  isInitializing = true;
  await useAuth0();
  isInitializing = false;
  return instance;
};
const useAuth0 = async () => {
  const auth0Client = await createAuth0Client({
    domain: auth0Config.domain,
    clientId: auth0Config.clientId,
    redirect_uri: auth0Config.redirectUri,
    authorizationParams: {
      audience: auth0Config.audience,
      redirect_uri: auth0Config.redirectUri,
    },
    cacheLocation: 'localstorage',
  });

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        isLoading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: auth0Client,
        popupOpen: false,
        error: null,
      };
    },
    methods: {
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      logout(options) {
        return this.auth0Client.logout(options);
      },
      async getAccessTokenSilently(o) {
        try {
          // Wait for Auth0 client initialization to complete
          return await this.auth0Client.getTokenSilently(o);
        } catch (e) {
          this.error = e;
          return null;
        }
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      try {
        // If the user is returning to the app after authentication...
        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
          // handle the redirect and retrieve tokens
          await this.auth0Client.handleRedirectCallback();

          this.error = null;
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        this.isLoading = false;
      }
    },
  });

  return instance;
};

export const getInstance = async () => {
  // Ensure that Auth0 is initialized before returning the instance
  await initializeAuth0();
  return instance;
};
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = getInstance();
  },
};